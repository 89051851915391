<template>
    <iframe @load="resizeIframe" :srcdoc="preview" width="100%" height="100%"></iframe>
</template>

<script>

export default {
    props: {
        preview: {
			type: String,
			required: true,
		},
    },
    methods: {
        resizeIframe() {
            var iframe = this.$el;
            iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 25) + 'px';
        }
    }
}
</script>

<style scoped>
    iframe {
        width: 100%;
        height: 100%;
        border: none;
        overflow: hidden;
    }
</style>