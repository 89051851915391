<template>
    <div class="reports">
        <table class="table">
            <thead>
                <tr>
                    <th class="col-xs-2">
                        {{$t('reports.month')}}
                    </th>
                    <th class="col-xs-2">
                        {{$t('reports.sent')}}
                    </th>
                    <th class="col-xs-2">
                        {{$t('reports.type')}}
                    </th>
                    <th class="col-xs-2">
                        {{$t('reports.state')}}
                    </th>
					<th class="col-xs-2">
                        {{$t('reports.opens')}}
                    </th>
                    <th class="col-xs-2">
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="n in limit" v-if="!hasLoaded">
                    <td class="col-xs-5">
                        <span class="mock">month '12</span>
                    </td>

                    <td class="col-xs-5">
                        <span class="mock">12/12/1234</span>
                    </td>

                    <td class="col-xs-2">
                        <div class="pull-right">
                            <span class="mock">--</span>
                        </div>
                    </td>
                </tr>

                <tr v-for="(report, index) in reports" :class="{notSent: (report.sent == null)}" v-if="hasLoaded">
                    <td class="col-xs-2">
                        {{report.created_at | moment("MMMM") | capitalize}}
                        '{{report.created_at | moment("YY")}}
                    </td>

                    <td class="col-xs-2">
                        <template v-if="report.sent">
                            {{report.sent | moment("calendar")}}
                        </template>

                        <template v-else>
                            {{$t('reports.notSent')}}
                        </template>
                    </td>

                    <td class="col-xs-2">
                        {{report.type | capitalize}}
                    </td>

                    <td class="col-xs-2">
                        {{report.status | capitalize}}
                    </td>

					<td class="col-xs-2">
                        {{report.opens}}
                    </td>

                    <td class="col-xs-2">
                        <div class="pull-right">
                            <template v-if="report.sent == null">
                                <button @click="confirmSend(index)" class="btn btn-danger btn-sm">
                                    <span class="fa fa-paper-plane-o fa-fw"></span>
                                    {{$t('reports.send')}}
                                </button>
                            </template>


                            <router-link :to="{name: 'backend.performancereports.view-report', params: {id: report.id}}" class="view">
                                <span class="pe-7s-angle-right pull-right"></span>
                            </router-link>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="pull-right">
            <pagination @paginate="paginate" :limit="this.limit" :page="this.page" :total="this.total"></pagination>
        </div>

        <div class="clearfix"></div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .reports {
        .view {
            span {
                color: #000;
                font-size: 20pt;
                margin-left: 25px;
            }
        }

        table {
            tr {
                &.notSent {
                    color: $focusAlert;
                }
            }
        }
    }
</style>

<script>
import Card from '@/app/shared/components/Card'
import Pagination from '@/app/layout/components/paginator/Pagination'

const PerformanceReportBatchService = require('@/services/performance-report/PerformanceReportBatchService')

export default {
    data() {
        return {
            total: 0,
            page: 1,
            limit: 15,
            reports: [],
            hasLoaded: false,
        }
    },

    mounted() {
        this.load()
    },

    methods: {
        load() {
            this.hasLoaded = false;

			const options = {
                limit: this.limit,
				skip: this.page === 1 ? 0 : ((this.page - 1) * this.limit),
            };

			PerformanceReportBatchService.reportBatches(options, response => {
				const body = response.data
                const rows = body.rows

				this.total = body.total
                this.reports = rows
                this.hasLoaded = true
            }, error => {
				this.hasLoaded = true
            })
        },

        paginate(page) {
            this.page = page
            this.load()
        },

        confirmSend(index) {
            let report = this.reports[index]

            this.$swal({
                text: $t('reports.confirmSendReport'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: $t('reports.confirm'),
                cancelButtonText: $t('reports.regret')
            }).then(() => {
                PerformanceReportBatchService.queueBatch(report.id, (response) => {
                    report.state = response.data.state
                })
            })
        }
    },

    components: {
        Card,
        Pagination
    }
}
</script>
