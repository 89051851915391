<template>
	<div class="reports-view" v-on:keyup.37="previous" v-on:keyup.39="next">
		<div class="row">
			<div class="col-lg-8">
				<div class="preview" :class="{deactivated: deactivated, temporaryDeactivated: temporaryDeactivated}">
					<card icon="pe-7s-look" :headline="$t('reports.preview')">
						<hr/>

						<loader v-if="!hasLoaded || !preview"></loader>

						<div class="overlay-container">
							<!-- <div v-if="hasLoaded && preview" v-html="preview"></div> -->
							<preview-component v-if="hasLoaded && preview" :preview="preview"></preview-component>
							<div class="overlay"></div>
						</div>

						<div class="clearfix"></div>
					</card>
				</div>
			</div>

			<div class="col-md-4">
				<card icon="pe-7s-user" :headline="$t('reports.customer')">
					<hr/>
					<template v-if="formattedList.length">
						<input type="text" v-model="search" class="customer-list-search" placeholder="Søg" />
						<ul class="customer-list">
							<li :class="{'active': selected.value == item.value}" @click="selected = item" v-for="(item, index) in filteredCustomerList" v-bind:key="index">
								{{ item.label }}
							</li>
						</ul>
						<a v-show="this.search == ''" class="load_more_customers" @click.prevent="showAllCustomers = !showAllCustomers" href="#" v-text="showAllCustomers ? $t('reports.show_fewer_customers') : $t('reports.show_all_customers')"></a>
					</template>

					<loader size="small" v-if="!formattedList.length"></loader>
				</card>

				<div class="priority">
					<card icon="pe-7s-news-paper" :headline="$t('reports.report')">
						<hr/>

						<div class="row" v-if="reports[selectedIndex]">
							<div class="col-md-6">
								<strong>{{ $t('reports.priority') }}: </strong>
								<p v-if="reports[selectedIndex]">{{ reports[selectedIndex].priority }}</p>
							</div>
							<div class="col-md-6" v-if="reports[selectedIndex].customer">
								<strong>Brugertype:</strong>

								<p v-if="reports[selectedIndex].customer && reports[selectedIndex].customer.organization && !reports[selectedIndex].customer.organization.freemium">
									Kunde<br><small>Siden
									{{ createdDate }}</small></p>
								<p v-else>SaaS</p>
							</div>
						</div>
					</card>
				</div>

				<div class="updating-settings">
					<card icon="pe-7s-settings" :headline="$t('reports.settings')">
						<hr/>

						<loader v-if="updatingSettings || !hasLoaded" size="small"></loader>

						<div class="row">
							<div class="col-md-6">
								<div class="form-group pull-left">
									<strong>{{ $t('reports.temporaryDeactivate') }}</strong><br/>
									<input type="checkbox" v-model="temporaryDeactivated">
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group pull-right text-right">
									<strong>{{ $t('reports.deactivate') }}</strong><br/>
									<input type="checkbox" v-model="deactivated">
								</div>
							</div>
						</div>
					</card>
				</div>

				<div class="integrations" v-if="reports[selectedIndex]">
					<card icon="pe-7s-info" headline="Kunde" description="Integrationer og produkter">
						<hr/>

						<loader v-if="!hasLoadedCustomer" size="small"></loader>

						<div class="row" v-else>
							<div class="col-md-6">
								<strong>Products:</strong>

								<div v-for="product in reports[selectedIndex].customer.products" :key="product.id">-
									{{ product.discr | capitalize }}
								</div>
							</div>

							<div class="col-md-6">

								<strong>Integrations:</strong>

								<div v-for="integration in reports[selectedIndex].customer.integrations"
									 :key="integration.id">-
									{{ integration.service.name }}
								</div>
							</div>
						</div>
					</card>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import "~@/assets/scss/variables/_colors.scss";

.pagination-container {

	&.--performance-reports {
		display: flex;
		justify-content: center;
		white-space: nowrap;

		ul {
			display: flex;
			align-items: center;
			padding-left: 0px;
			margin-bottom: 0px;
			flex-flow: row wrap;

			li:nth-of-type(1) {
				a {
					margin-left: 0px;
				}
			}
		}
	}
}



.reports-view {

	.customer-list-search {
		width: 100%;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 5px;
		margin-bottom: 10px;
	}

	.customer-list {
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0px !important;
		max-height: 350px;
		overflow: hidden;
		overflow-y: auto;
		padding-right: 5px !important;
		margin-right: -10px !important;
		overscroll-behavior: contain;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}

		li {
			cursor: pointer;
			padding: 10px;
			border: 1px solid #ccc;
			border-radius: 5px;
			margin-bottom: 10px;
			width: 100%;
			transition: all 0.2s ease-in-out;

			&.active {
				background-color: $digital-blue;
				color: $white;
				border-color: $digital-blue;

				&:hover {
					background-color: $digital-blue;
				}
			}

			&:hover {
				background-color: #f5f5f5;
			}

			&:nth-last-of-type(1) {
				margin-bottom: 0px;
			}
		}
	}

	.load_more_customers {
		display: block;
		text-align: center;
		margin-top: 10px;
	}

	.updating-settings {
		position: relative;

		.loader {
			color: #fff;
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			left: 0;
			top: 0;
			z-index: 99;
			font-size: 20px;
			margin-top: 16px;
			padding: 40px;
		}
	}

	.preview {
		position: relative;

		.overlay-container {
			position: relative;
			max-width: 100%;
			width: 100%;
			margin: auto;

			.overlay {
				display: none;
			}
		}

		&.deactivated {
			.overlay {
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}

		&.temporaryDeactivated {
			.overlay {
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-color: rgba(23, 0, 47, 0.5);
			}
		}
	}
}
</style>

<script>
import Card from '@/app/shared/components/Card'
import Loader from '@/app/shared/components/Loader.vue'
import Pagination from "../../layout/components/paginator/Pagination.vue"
import PreviewComponent from '../components/preview.vue'

const moment = require('moment');
const CustomerService = require('@/services/customers/CustomerService')
const PerformanceReportService = require('@/services/performance-report/PerformanceReportService')
const PerformanceReportBatchService = require('@/services/performance-report/PerformanceReportBatchService')

export default {
	data() {
		return {
			id: null,
			hasLoaded: true,
			reports: [],
			formattedList: [],
			total: null,
			page: 1,
			limit: 100,
			skip: 0,
			selected: null,
			selectedIndex: 0,
			preview: null,
			deactivated: false,
			temporaryDeactivated: 0,
			updatingSettings: false,
			awaitHasLoaded: false,
			hasLoadedCustomer: true,
			onlyDisabled: false,
			search: '',
			showAllCustomers: false
		}
	},

	watch: {
		selected() {
			if (this.selected) {
				if (this.selected.value === this.selectedIndex) {
					return
				}
			}

			const index = this.reports.findIndex((report) => report.customer.id === this.selected.value);

			if (index < 0) {
				return
			}

			this.selectedIndex = index;

			if (!this.hasLoaded) {
				if (!this.awaitHasLoaded) {
					this.awaitHasLoaded = setInterval(this.reload, 500)
				}

				return;
			}

			this.preview = null
			this.loadPreview()
			this.loadSettings()
		},

		temporaryDeactivated() {
			if (!this.hasLoaded) {
				return;
			}

			if (this.temporaryDeactivated) {
				this.temporaryDeactivate()
				return;
			}

			this.temporaryMarkReport('reactivate')
		},

		deactivated() {
			if (!this.hasLoaded) {
				return;
			}

			if (this.deactivated) {
				this.unsubcribe()
				return;
			}

			this.subscribe()
		}
	},

	computed: {
		createdDate() {
			if (this.reports[this.selectedIndex] === undefined) {
				return ''
			}
			const customer = this.reports[this.selectedIndex].customer
			return moment(customer.created.date).format('LL')
		},
		filteredCustomerList() {
			// if search is empty, show the first 25 items
			if (this.search.length > 2 && this.search !== '') {
				return this.formattedList.filter((item) => {
					return item.label.toLowerCase().includes(this.search.toLowerCase())
				})
			} else {
				if (this.showAllCustomers) {
					return this.formattedList
				}
				return this.formattedList.slice(0, 25)
			}
		}
	},

	mounted() {
		this.id = this.$route.params.id;
		this.loadReports()
	},

	methods: {
		loadReports() {
			this.hasLoadedCustomer = false

			const options = {
				batchId: this.id
			}

			PerformanceReportBatchService.batchReports(options, response => {
				this.reports = Object.values(response.data.rows)
				this.total = response.data.total
				this.loadCustomers()
			})
		},

		loadCustomers() {
			this.reports.forEach((report, index, array) => {

				this.formattedList.push({
					label: report.customer.domain + ' > ' + report.customer.name,
					value: report.customer.id
				})

				if (index === (array.length - 1)) {

					this.setSelectedIndex(0)
					this.hasLoadedCustomer = true
				}
			});
		},

		paginate(page) {
			this.page = page
			this.reports = []
			this.formattedList = []
			this.loadReports()
		},

		next() {
			this.setSelectedIndex(this.selectedIndex + 1)
		},

		previous() {
			this.setSelectedIndex(this.selectedIndex - 1)
		},

		setSelectedIndex(newValue) {
			const max = this.reports.length - 1
			const min = 0

			if (this.selectedIndex < min) {
				this.selectedIndex = max
			} else if (this.selectedIndex > max) {
				this.selectedIndex = min
			} else {
				this.selectedIndex = newValue
			}

			const customer = this.reports[this.selectedIndex].customer

			this.selected = {
				label: customer.domain + ' > ' + customer.name,
				value: customer.id
			};
		},

		loadPreview() {
			if (!this.selected) {
				return;
			}

			const options = {
				type: 'html',
				reportId: this.reports[this.selectedIndex].id
			}

			PerformanceReportBatchService.downloadReport(options, response => {
				const regex = /(?:.dark-card{.*max-width:)(.*)(?:;)/;
				this.preview = response.data.replace(regex, (match, group1) => {
					return match.replace(group1, 'initial');
				})
			})
		},

		loadSettings() {
			this.updatingSettings = true;

			if (!this.selected && !this.reports[this.selectedIndex]) {
				return;
			}

			this.$http.get('users/clients', {
				params: {
					includes: ['notificationSubscriptions'],
					filter_groups: [
						{
							filters: [
								{
									key: 'customer',
									operator: 'eq',
									value: this.selected.value,
								},
								{
									key: 'notificationSubscriptions.slug',
									operator: 'eq',
									value: this.reports[this.selectedIndex].type + '-report', // Todo: This should not be necessarry, but is how the reports are made currently
								}
							]
						}
					]
				}
			}).then((response) => {
				var body = response.data;

				this.deactivated = body.rows.length === 0;
			});

			this.temporaryDeactivated = this.reports[this.selectedIndex].state === 'deactivated';
			this.updatingSettings = false;
		},

		reload() {
			if (!this.hasLoaded) {
				return;
			}

			this.loadPreview();
			this.loadSettings();

			clearInterval(this.awaitHasLoaded);
			this.awaitHasLoaded = null;

		},

		temporaryDeactivate() {
			this.temporaryMarkReport('deactivate')
		},

		temporaryMarkReport(mark) {
			const options = {
				reportId: this.reports[this.selectedIndex].id,
				mark: mark
			}
			PerformanceReportBatchService.markReport(options)
		},

		unsubcribe() {
			if (!this.selected && !this.reports[this.selectedIndex]) {
				return;
			}

			PerformanceReportService.unsubscribe(this.selected.value, this.reports[this.selectedIndex].type)
		},

		subscribe() {
			if (!this.selected && !this.reports[this.selectedIndex]) {
				return;
			}

			PerformanceReportService.subscribe(this.selected.value, this.reports[this.selectedIndex].type)
		}
	},
	components: {
		Pagination,
		Loader,
		Card,
		PreviewComponent
	}
}
</script>
