const BaseService = require('@/services/BaseService')

const performanceReports = 'performance-reports-batches'
const reports = 'reports'

const batchReports = (options, cb, errorCb) => {
	BaseService.post(reports + '/batchReports', options, cb, errorCb)
}

const reportBatches = (options, cb, errorCb) => {
	BaseService.post(reports + '/reportBatches', options, cb, errorCb)
}

const markReport = (options, cb, errorCb) => {
	BaseService.post(reports + '/markReport', options, cb, errorCb)
}

const downloadReport = (options, cb, errorCb) => {
	BaseService.post(reports + '/downloadReport', options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
	BaseService.get(performanceReports, options, cb, errorCb)
}

const queueBatch = (id, cb, errorCb) => {
	BaseService.get(performanceReports + '/' + id + '/queue', {}, cb, errorCb)
}

export {
	findAll,
	queueBatch,
	batchReports,
	reportBatches,
	markReport,
	downloadReport
}
