<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <tab-navigation>
                        <ul>
                            <li>
                                <router-link :to="{name: 'backend.performancereports.list'}">
                                    {{$t('reports.reports')}}
                                </router-link>
                            </li>
                        </ul>

                        <div class="clearfix"></div>

                        <hr />

                        <router-view></router-view>
                    </tab-navigation>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import TabNavigation from '@/app/layout/components/TabNavigation'

export default {
    computed: {
        routeName() {
            return this.$route.name;
        }
    },

    watch: {
        routeName() {
            this.checkRoute();
        }
    },

    mounted() {
        this.checkRoute();
    },

    methods: {
        checkRoute() {
            var routeName = this.routeName;

            if (routeName == 'backend.performancereports') {
                this.$router.push({
                    name: 'backend.performancereports.list'
                });
            }
        }
    },

    components: {
        TabNavigation
    },
}
</script>
