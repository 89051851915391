const BaseService = require('@/services/BaseService')

const namespace = 'performance-reports'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const pdf = (id, options, cb, errorCb) => {
    BaseService.get(`${namespace}/${id}/pdf`, options, cb, errorCb)
}

const deactivate = (id, cb, errorCb) => {
  BaseService.remove(`${namespace}/${id}/deactivate/`, {}, cb, errorCb)
}

const reactivate = (id, cb, errorCb) => {
  BaseService.post(`${namespace}/${id}/reactivate/`, {}, cb, errorCb)
}

const unsubscribe = (id, type, cb, errorCb) => {
  BaseService.remove(`${namespace}/subscriptions/unsubscribe/${type}/${id}`, {}, cb, errorCb)
}

const subscribe = (id, type, cb, errorCb) => {
  BaseService.post(`${namespace}/subscriptions/subscribe/${type}/${id}`, {}, cb, errorCb)
}

export {
  find,
  findAll,
  pdf,
  deactivate,
  reactivate,
  unsubscribe,
  subscribe
}
